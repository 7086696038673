import React, { useReducer, useEffect } from 'react';
import { initializeIcons } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { BULK_SPELL_CHECK_ENDPOINT } from '../config/constants';
import { getWordsFromDocument, replaceWord } from '../officeapi';
// import { WordInfo } from '../types/general';
import Loading from './Loading';
// import { WordInfo } from '../types/general';
import { reducer, initialState } from '../reducers/wordListReducer';
import WordCard from './WordCard';
import { Accordion } from '@fluentui/react-accordion';
import { Button } from '@fluentui/react-components';

initializeIcons();

/**
 *
 * To use this component in dev mode of React (npm start)
 * Uncomment below and remove it from the dispatch setup
 * comment out isLoading
 * comment out the Office.onReady function
 * // getWordsFromDocument(100).then(fetchedWords => {
 * // checkWords(fetchedWords);
 * // });
 * */
// const initialData: WordInfo[] = [
//   { suggestions: ['کارد', 'کورد'], word: 'کرد' },
//   { suggestions: ['کوردستان', 'کوێستان', 'گۆڕستان'], word: 'کردستان' },
//   { suggestions: ['جەستەی', 'جەستە', 'چوستی', 'جەستەیی'], word: 'جەستی' },
// ];
interface WordListProps {
  user: any;
  token: string | null;
}

function WordList({ user, token }: WordListProps) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    words: [],
    isLoading: true, // Set isLoading to false for development
  });
  const { t } = useTranslation();

  async function checkWords(words: string[]) {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await fetch(BULK_SPELL_CHECK_ENDPOINT, {
        method: 'POST',
        headers: {
          'Authorization': 'Token ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ words }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const wordsWithSuggestions = data.results.filter(
        (result: { suggestions: string[] }) => result.suggestions.length > 0
      );
      dispatch({ type: 'SET_WORDS', payload: wordsWithSuggestions });
    } catch (error) {
      console.error('Error checking words:', error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }

  useEffect(() => {
    if (typeof Office !== 'undefined' && Office.context) {
      Office.onReady(() => {
        getWordsFromDocument(2000).then(fetchedWords => {
          checkWords(fetchedWords);
        });
      });
    } else {
      console.log('Office.js is not loaded. Please run within an Office add-in.');
    }
  }, []);

  if (state.isLoading) {
    return <Loading />;
  }

  const handleIgnoreAll = (word: string) => {
    // TODO: restructure this component to use
    // a centralised dispatch/reducer
    dispatch({
      type: 'REMOVE_WORD',
      payload: word
    });
  };

  const handleSuggestionClick = (originalWord: string, suggestedWord: string) => {
    replaceWord(originalWord, suggestedWord);
    // Optionally update the state via reducer if needed
  };

  return (
    <div style={{ paddingBottom: '50px' }}>
      <h2>{state.words.length ?
        `${t('typolist')} (${state.words.length})` : t('nothingtoshow')}</h2>
      {!state.words.length && <Button onClick={() => {
        getWordsFromDocument(100).then(fetchedWords => {
          checkWords(fetchedWords);
        })
      }}>{t('refresh')}</Button>}
      <Accordion>
        {state.words.map((wordInfo) => (
          <WordCard word={wordInfo.word}
            onIgnore={(w: string) => console.log(w)}
            onIgnoreAll={handleIgnoreAll}
            suggestions={wordInfo.suggestions}
            onSuggestionClick={handleSuggestionClick} />
        ))}
      </Accordion>
    </div>
  );
}

export default WordList;
