import React from 'react';
import { Spinner } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

function LoadingIndicator() {
  const { t } = useTranslation();

  const spinnerStyles = {
    circle: {
      // Define the color and size of the spinner circle
      borderColor: 'black black black transparent',  // Makes the spinner black
      borderWidth: '3px',  // Thicker border for larger appearance
      width: 50,  // Adjust size of the spinner (width in pixels)
      height: 50,  // Adjust size of the spinner (height in pixels)
    },
    label: {
      color: 'black',  // Label color
      marginTop: 12,  // Space between spinner and label
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spinner
        label={t('processing')}
        ariaLive="assertive"
        styles={spinnerStyles}
      />
    </div>
  );
}

export default LoadingIndicator;
