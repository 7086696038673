import { WordInfo } from "../types/general";

export interface WordListState {
  words: WordInfo[];
  currentWord?: string;
  currentWordSuggestions: string[];
  isLoading: boolean;
}

export type WordListAction =
  | { type: 'SET_WORDS'; payload: WordInfo[] }
  | { type: 'SET_LOADING'; payload: boolean }
  | {
    type: 'SET_CURRENT_WORD';
    payload: { word: string, suggestions: string[] }
  }
  | { type: 'CLEAR_CURRENT_WORD' }
  | { type: 'REMOVE_WORD'; payload: string };

export const initialState: WordListState = {
  words: [],
  currentWord: undefined,
  currentWordSuggestions: [],
  isLoading: true,
};

export function reducer(state: WordListState,
  action: WordListAction): WordListState {
  switch (action.type) {
    case 'SET_WORDS':
      return { ...state, words: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_CURRENT_WORD':
      return {
        ...state, currentWord: action.payload.word,
        currentWordSuggestions: action.payload.suggestions
      };
    case 'CLEAR_CURRENT_WORD':
      return {
        ...state, currentWord: undefined,
        currentWordSuggestions: []
      };
    case 'REMOVE_WORD':
      const updatedWords = state.words
        .filter(word => word.word !== action.payload);
      const shouldClearCurrentWord = state.currentWord === action.payload;
      console.log("Updated words after removal:", updatedWords);
      console.log("Should clear current word:", shouldClearCurrentWord);
      return {
        ...state,
        words: updatedWords,
        currentWord: shouldClearCurrentWord ?
          undefined : state.currentWord,
        currentWordSuggestions:
          shouldClearCurrentWord ? [] : state.currentWordSuggestions,
      };

    default:
      return state;
  }
}
