// src/contexts/AuthContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
}

interface User {
  username: string;
  email: string;
}

interface AuthContextProps {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
};

export const AuthContext = createContext<AuthContextProps>({
  authState: initialAuthState,
  setAuthState: () => { },
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthState);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};
