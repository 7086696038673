// src/components/Footer.js
import React from 'react';
import { Text } from '@fluentui/react';

const footerStyles = {
  root: {
    height: '30px',
    padding: '10px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#c2a26a',
    color: 'white',
    textAlign: 'center'
  }
};

function Footer({ isAuthenticated, onLogout }) {
  return (
    <div style={footerStyles.root}>
      <Text variant="small">© 2024 نووسینگەی پرۆژە زانستیەکان</Text>
      {isAuthenticated && (
        <Text onClick={onLogout}>
          Logout
        </Text>
      )}
      <Text variant="small" styles={{
        root: {
          padding: '20px', fontSize: '1.2em'
        }
      }}>
        v: alpha.1
      </Text>
    </div>
  );
}

export default Footer;
