import React, { useState } from 'react';
import {
  Text, DefaultButton,
} from '@fluentui/react';
import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { useTranslation } from 'react-i18next';

import { SUGGESTION_LIMIT_DEFAULT } from '../config/constants';
import WordIconButton from './IconButton';
import { useTheme } from '../contexts/ThemeContext';

interface WordCardProps {
  word: string;
  suggestions: string[];
  onSuggestionClick: (suggestion: string, suggestedWord: string) => void;
  onIgnore: (word: string) => void;
  onIgnoreAll: (word: string) => void;
}

const WordCard = ({ word, suggestions, onSuggestionClick, onIgnore, onIgnoreAll }: WordCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div style={{
      marginBottom: '10px',
      border: '1px solid lightgray',
      borderRadius: '10px',
      padding: '10px',
      position: 'relative',
      minWidth: '200px',
      background: isDarkMode ? 'black' : 'white',
      color: isDarkMode ? 'white' : 'black',
    }}>
      <AccordionItem value={word}>
        <AccordionHeader onClick={toggleExpand} as="div">
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <div style={{
              display: 'flex',
            }}>
              <span>{`${t('wrong')} - `}</span>
              <Text
                variant="mediumPlus"
                style={{
                  color: isDarkMode ? 'orange' : 'navy',
                  fontWeight: 'bold',
                  textDecoration: 'line-through',
                  cursor: 'pointer',
                  marginLeft: 'auto',
                }}
              >
                {word}
              </Text>
            </div>
            <div style={{
              position: 'absolute', left: 0,
            }}>
              <WordIconButton
                iconProps={{ iconName: 'Delete' }}
                title={t('ignoreAll')}
                onClick={() => onIgnoreAll?.(word)}
                ariaLabel={t('ignoreAllInstances')}
              />
              <WordIconButton
                iconProps={{ iconName: 'Blocked2' }}
                title={t('ignore')}
                onClick={() => onIgnore?.(word)}
                ariaLabel={t('ignoreWord')}
              />
            </div>
          </div>
        </AccordionHeader>
        <AccordionPanel>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {suggestions.slice(0, SUGGESTION_LIMIT_DEFAULT).map(suggestion => (
              <DefaultButton
                key={suggestion}
                onClick={() => onSuggestionClick?.(word, suggestion)}
                text={suggestion}
              />
            ))}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </div>
  );
};

export default WordCard;
