import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "suggestionsFor": "Suggestions for ",
      "check": "Check",
      "processing": "Processing your document...",
      "typolist": "Typo list",
      "ignorethis": "Ignore this instance",
      "ignoreallexp": "Ignore all instances of this word",
      "ignoreall": "Ingore All",
      "ignore": "Ignore",
      "cancel": "Cancel",
      "ok": "OK",
      "nothingtoshow": "Nothing to show",
      "wrong": "Wrong",
      "headerTitle": "Kurdish Spellchecker",
      "username": "Username",
      "password": "Password",
      "googleSingIn": "Sign in with Google",
      "login": "Login",
      "welcomeMessage": "Welcome to the Kurdish spellchecker.",
      "loginWithDialog": "Login with Dialog",
      "signUp": "Sign Up",
      "registrationNotice": "Currently registration is only for a closed list.",
      "briefDescription": "Kurdish spellchecker is a tool to check your text for spelling mistakes. " +
        "The tool is available as a Microsoft Word add-in.",
      "refresh": "Refresh",
    }
  },
  'ckb-IQ': {
    translation: {
      "suggestionsFor": "پێشنیار بۆ ",
      "check": "پیاچوونەوە",
      "processing": "تەماشای دۆکومێنتەکەت دەکرێت...",
      "typolist": "لیستی هەڵەبژێر",
      "ignorethis": "ئەمە پشتگوێ بخە",
      "ignoreallexp": "هەموو ئەم وشانە پشتگوێ بخە",
      "ignoreall": "ئەمانە پشتگوێ بخە",
      "ignore": "پشتگوێ",
      "cancel": "پەشیمان",
      "ok": "باشە",
      "nothingtoshow": "هیچ نیە نیشان بدرێت",
      "wrong": "هەڵە",
      "headerTitle": "هەڵەبژێری کوردان",
      "username": "ناوی بەکارهێنەر",
      "password": "وشەی تێپەڕ",
      "googleSingIn": "چوونەژوورەوە بە گووگڵ",
      "login": "چوونەژوورەوە",
      "welcomeMessage": "بەخێرهاتی بۆ هەڵەبژێری کوردان.",
      "loginWithDialog": "چوونەژوورەوە بە ئۆفیس",
      "signUp": "خۆ تۆمارکردن",
      "registrationNotice": "لە ئێستادا خۆ تۆمارکردن تەنها بۆ لیستێکی داخراوە.",
      "briefDescription": "هەڵەبژێری کوردان ئامرازێکە بۆ پشکنینی دەق بۆ هەڵەی نووسین." +
        " ئامرازەکە بەردەستە وەک پێوەکراوێک لە مایکرۆسۆفت وێرد.",
      "refresh": "نوێکردنەوە",
    }
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ckb-IQ', // Set language to ckb-IQ
    fallbackLng: 'en', // Use English as fallback
    interpolation: {
      escapeValue: false // React already safes from XSS
    }
  });

export default i18n;
