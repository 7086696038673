// src/components/Header.js
import React from 'react';
import { Stack, Text, IconButton, IStackStyles, IButtonStyles } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../contexts/ThemeContext';

const headerStyles: IStackStyles = {
  root: {
    height: 50,
    backgroundColor: '#c2a26a',
    padding: '0 20px',
    color: 'white'
  }
};

const iconButtonStyles: Partial<IButtonStyles> = {
  icon: {
    color: 'black'
  },
  rootHovered: {
    backgroundColor: 'transparent',
    color: 'black'
  },
  rootPressed: {
    color: 'black'
  }
};

const stackTokens = { childrenGap: 20 };
interface HeaderProps {
  toggleThemeCalback: () => void;
}

const Header: React.FC = (toggleThemeCalback) => {
  const { t } = useTranslation();
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <Stack horizontal tokens={stackTokens} horizontalAlign="space-between" verticalAlign="center" styles={headerStyles}>
      <Text variant="large" style={{ fontWeight: 'bold' }}>
        {t('headerTitle')}
      </Text>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: isDarkMode ? 'Sunny' : 'ClearNight' }}
          title="Toggle theme"
          ariaLabel="Toggle theme"
          onClick={toggleTheme}
        />
        {/*
          todo: Add settings component and icon
          <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: 'Settings' }}
          title="Settings"
          ariaLabel="Settings"
          onClick={() => console.log('Settings clicked')}
        /> */}
      </Stack>
    </Stack>
  );
}

export default Header;
