import React from 'react';
import { IconButton, IIconProps, IButtonStyles } from '@fluentui/react';

const defaultIconProps: IIconProps = {
  iconName: 'Cancel', // Default icon
};

const iconStyles: IButtonStyles = {
  root: {
    selectors: {
      ':hover': {
        color: 'gray', // Color on hover
      },
    },
  },
  icon: {
    fontSize: 16, // Icon size
  },
};

interface WordIconButtonProps {
  title: string;
  iconProps?: IIconProps;
  onClick: () => void;
  ariaLabel: string;
}

const WordIconButton: React.FunctionComponent<WordIconButtonProps> = ({
  title,
  iconProps = defaultIconProps,
  onClick,
  ariaLabel,
}) => (
  <IconButton
    title={title}
    iconProps={iconProps}
    styles={iconStyles}
    onClick={onClick}
    ariaLabel={ariaLabel}
  />
);

export default WordIconButton;
