// src/theme.ts
import { createTheme, ITheme } from '@fluentui/react';

const lightTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#f3f9fd',
    themeLighter: '#d0e7f8',
    themeLight: '#a9d3f2',
    themeTertiary: '#5ca9e5',
    themeSecondary: '#1a86d9',
    themeDarkAlt: '#006cbe',
    themeDark: '#005ba1',
    themeDarker: '#004377',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
  fonts: {
    small: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
    medium: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
    large: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
    xLarge: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
  },
});

const darkTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#f3f9fd',
    themeLighter: '#d0e7f8',
    themeLight: '#a9d3f2',
    themeTertiary: '#5ca9e5',
    themeSecondary: '#1a86d9',
    themeDarkAlt: '#006cbe',
    themeDark: '#005ba1',
    themeDarker: '#004377',
    neutralLighterAlt: '#2f2f2f',
    neutralLighter: '#2c2c2c',
    neutralLight: '#282828',
    neutralQuaternaryAlt: '#262626',
    neutralQuaternary: '#242424',
    neutralTertiaryAlt: '#3c3c3c',
    neutralTertiary: '#a6a6a6',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1f1f1f',
  },
  fonts: {
    small: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
    medium: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
    large: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
    xLarge: {
      fontFamily: 'Droid Arabic Naskh, serif',
    },
  },
});

export { lightTheme, darkTheme };
