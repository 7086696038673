/* global Office */
import React, { useEffect, useContext } from 'react';
import {
  Stack, IStackStyles, ThemeProvider as FluentThemeProvider, IStackTokens,
  PrimaryButton, Text
} from '@fluentui/react';
import WordList from './components/WordList';
import Footer from './components/Footer';
import Header from './components/Header';
// import Login from './components/Login';
import { AuthContext } from './contexts/AuthContext';
import { useTheme } from './contexts/ThemeContext';
import { lightTheme, darkTheme } from './theme';
import { ACTIVE_SERVER } from './config/constants';
import { useTranslation } from 'react-i18next';

const stackTokens: IStackTokens = { childrenGap: 15 };

const containerStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
};

const headerFooterStyles: IStackStyles = {
  root: {
    flex: '0 0 auto'
  }
};

const contentStyles: IStackStyles = {
  root: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

function App() {
  // TODO: Implement the useReducer hook
  // const [state, dispatch] = useReducer(reducer, initialState);
  const { authState, setAuthState } = useContext(AuthContext);
  const [token, setToken] = React.useState<string | null>(localStorage.getItem('token'));
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  useEffect(() => {
    const fetchUser = async () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        const response = await fetch('/wordaddin/user/', {
          headers: { 'Authorization': 'Token ' + storedToken },
        });
        if (response.ok) {
          const data = await response.json();
          setAuthState({ isAuthenticated: true, user: data });
        } else {
          setAuthState({ isAuthenticated: false, user: null });
          localStorage.removeItem('token');
        }
      }
    };

    fetchUser();
  }, [setAuthState, token]);

  const handleDialogAuth = () => {
    console.log(ACTIVE_SERVER + '/dialog-auth.html');

    Office.onReady((info: any) => {
      if (info.host && Office.context && Office.context.ui) {
        try {
          Office.context.ui.displayDialogAsync(
            ACTIVE_SERVER + '/dialog-auth.html',
            { height: 60, width: 30 },
            (result: any) => {
              const dialog = result.value;
              dialog.addEventHandler(Office.EventType.DialogMessageReceived,
                (args: any) => {
                  console.log('App.tsx: message from dialog:', args);
                  const message = JSON.parse(args.message);
                  if (message.success) {
                    localStorage.setItem('token', message.token);
                    setToken(message.token);
                    setAuthState({ isAuthenticated: true, user: { username: '', email: '' } });
                    dialog.close();
                  } else {
                    setAuthState({ isAuthenticated: false, user: null });
                  }
                });
            }
          );
        } catch (error) {
          console.error('Error displaying dialog:', error);
        }
      } else {
        console.error('Office.context.ui is not available');
      }
    });
  };

  const handleDialogLogout = () => {
    Office.onReady((info: any) => {
      if (info.host && Office.context && Office.context.ui) {
        try {
          Office.context.ui.displayDialogAsync(
            ACTIVE_SERVER + '/dialog-logout.html',
            { height: 40, width: 30 },
            (result: any) => {
              const dialog = result.value;
              console.log('App.tsx: dialog:', dialog);

              dialog.addEventHandler(Office.EventType.DialogMessageReceived,
                (args: any) => {
                  const message = JSON.parse(args.message);
                  if (message.success) {
                    localStorage.removeItem('token');
                    setToken(null);
                    setAuthState({ isAuthenticated: false, user: null });
                    dialog.close();
                  } else {
                    console.error('Logout failed:', message.error);
                  }
                });
            }
          );
        } catch (error) {
          console.error('Error displaying dialog:', error);
        }
      } else {
        console.error('Office.context.ui is not available');
      }
    });
  };

  return (
    <FluentThemeProvider theme={theme}>
      <Stack styles={containerStyles}>
        <Stack.Item styles={headerFooterStyles}>
          <Header />
        </Stack.Item>
        <Stack.Item styles={contentStyles}>
          {authState.isAuthenticated ? (
            <>
              <WordList user={authState.user} token={token} />
            </>
          ) : (
            <>
              <Stack tokens={stackTokens} horizontalAlign="center">
                <Text>{t('briefDescription')}</Text>
                <PrimaryButton text={t('loginWithDialog')} onClick={handleDialogAuth} />
                <PrimaryButton text={t('signUp')} href={ACTIVE_SERVER + '/accounts/signup'} />
                <Text>{t('registrationNotice')}</Text>
              </Stack>
            </>
          )}
        </Stack.Item>
        <Stack.Item styles={headerFooterStyles}>
          <Footer isAuthenticated={authState.isAuthenticated} onLogout={handleDialogLogout} />
        </Stack.Item>
      </Stack>
    </FluentThemeProvider>
  );
}

export default App;
