/* global Word */
/* global Office */

export async function getWordsFromDocument(count) {
  return Word.run(async (context) => {
    const body = context.document.body;
    body.load('text');
    await context.sync();

    const text = body.text;
    let words = text.match(/\p{L}+/gu);  // Extract words
    if (!words) {
      console.log("No words found in the document.");
      return;
    }

    // Remove duplicates before setting state
    words = [...new Set(words)];  // Remove duplicates
    words = words.slice(0, count);  // Limit the number of words
    return words
  }).catch(error => {
    console.error("Error: " + error);
  });
}

// Function to replace the first instance of the word in the document
export function replaceWord(originalWord, newWord) {
  Word.run(async (context) => {
    const body = context.document.body;
    body.load('text');
    await context.sync();

    // MAJOR ISSUE for Unicode characters
    // IF we use { matchWholeWord: true }
    // OR NOT
    const searchResults = context.document.body.search(
      originalWord, {
      // matchCase: true,  // Optional: for case sensitivity
      // matchWholeWord: true  // Optional: to ensure whole words only
    });
    context.load(searchResults, 'text');
    await context.sync();
    console.log(searchResults.text)
    if (searchResults.items.length > 0) {
      const range = searchResults.items[0]
      range.insertText(newWord, "Replace");
      await context.sync();

      await context.sync();

      // Select the new text which should bring it into view
      range.select("Select");
      await context.sync();
    }

  }).catch(error => {
    console.error("Error in replaceWord:", error);
  });
}
